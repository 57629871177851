.MuiButton-text {
  min-width: 65px !important;
  padding: 2px 4px !important;
  color: #235b76 !important;
  border-radius: 0 !important;
  border-right: #b3b2b2 1px dotted !important;
}

.leftcurve {
  border-radius: 5px 0px 0px 5px !important;
}
.shapes_icon .MuiPaper-root {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  margin-top: 15px;
  overflow: visible;
}

.shapes_icon .MuiPaper-root ul {
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
  background: #ffff;
  border-radius: 10px;
}
.shapes_icon .MuiPaper-root ul .MuiButton-text:hover,
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background-color: #fff !important;
}
.shapes_icon .MuiPaper-root ul:after {
  bottom: 100%;
  left: 8%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(0, 0, 0, 0);
  border-bottom-color: #dbdbdb;
  border-width: 8px;
  margin-left: -10px;
}
.shapes_icon .MuiPaper-root ul li {
  padding: 0px;
}

.shapes_icon .MuiPaper-root .MuiButton-text {
  background-color: #fff !important;
}
.shapes_icon div ul li button:last-child {
  border: none !important;
}

.toolfont {
  margin: 10px 2px;
  line-height: 0;
  font-size: 12px;
}

.tooltip:hover > div {
  margin-top: 0px;
}
.bgclr {
  height: 22px;
}
.MuiSvgIcon {
  font-size: 1.8rem !important;
}

/* Style the slider */
input[type='range'] {
  -webkit-appearance: none;
  width: 40px;
  height: 10px;
  background-color: #ededed;
  outline: none;
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  border: #235b76 1px solid;
  border-radius: 5px;
}

/* Style the slider thumb (handle) */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: #235b76;
  cursor: pointer;
  border-radius: 10px;
}

input[type='range']:hover {
  opacity: 1;
}
#txtTextSize {
  height: 25px;
  margin-left: 0px;
  border-radius: 5px;
  margin-top: 5px;
  width: 98%;
  border: #ccc 1px solid;
  padding-left: 8px;
  background: #f0f0f0;
}
#txtStyle {
  border-radius: 5px;
}
.toolbx {
  position: absolute !important;
  left: 55% !important;
  top: 9% !important;
}
#mgnt,
#srch,
#trsh,
#abc {
  font-size: 1.8rem !important;
}
#abc {
  font-size: 2rem !important;
}
.MuiButton {
  background: #ddd !important;
}

#btnDisplayMenu:after {
  bottom: 100%;
  left: 8%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(0, 0, 0, 0);
  border-bottom-color: #dbdbdb;
  border-width: 8px;
  margin-left: -10px;
}

.bgbox {
  margin-left: 0px;
  width: 35px !important;
}

.clr_tool {
  padding: 0px !important;
  display: flex;
  color: #bbb;
}

/* using javascript toolbar */
.lableIcon {
  border-right: #b3b2b2 1px dotted !important;
  padding-right: 15px;
  padding-top: 2px;
}

.lableIcon svg {
  color: #235b76 !important;
}

.dnone {
  display: none;
}

#menu {
  top: 112px;
  background-color: #fff;
  overflow: hidden;
  max-height: 0;
  padding: 0px;
  margin: 0 auto;
  position: absolute;
  top: 40px;
  z-index: 10;
}

#Home:checked + #menu {
  top: 112px;
  max-height: 127px;
  padding: 5px 0px 0px;
  border-radius: 4px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  margin-top: 15px;
  /* -webkit-transition: all 0.1s ease; */
  overflow: visible;
}

#Home:checked + #menu:after {
  bottom: 100%;
  left: 8%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(0, 0, 0, 0);
  border-bottom-color: #dbdbdb;
  border-width: 8px;
  margin-left: -10px;
}

#Home:checked + #menu li {
  display: inline;
}

#menu li svg {
  cursor: pointer;
  border-right: #ddd 1px dotted;
  padding: 0px 10px;
  color: #235b76 !important;
  width: 45px !important;
}

#menu li:last-child svg {
  border-right: none;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: rgba(97, 97, 97, 0.92);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  left: -5%;
  top: 26px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.lableIcon.tooltip .tooltiptext{
  top: 49px;
  left: -30%;
}
