
/* Side bar css */
.sidetxt span{
    font-size: 16px;
    font-weight: bold;
    color: #15003c;
    text-align: center;
}
.sidebar{padding: 0px !important; }
.namelbl{
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#dddddd+0,f0f0f0+100 */
    background: #dddddd; /* Old browsers */
    background: -webkit-linear-gradient(top,  #dddddd 0%,#f0f0f0 100%); /* Chrome10-25,Safari5.1-6 */
    font-size: 16px !important; 
    font-weight: bold !important;
    color: #15003c;
    padding: 14.5px 35px;
    border-bottom: #ddd 1px solid;
    margin-left: -3% !important;
}
.cardHead{
    text-transform: uppercase;
    font-size: 16px !important;
    font-weight: bold;
    font-family: 'Trade Gothic' !important;
    text-align: center;
    margin-bottom: 20px !important;
}
.cardtext{
    font-size: 48px !important;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Trade Gothic' !important;
    text-align: center;
}

.btnnw{
    background: #788f9d !important;
    cursor: pointer;
}
.btnspc{
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    font-weight: bold !important;
    font-size: 12px !important;
}

.tableBox{
    width: 98%;
    text-align: right;
}
.tbl thead tr th:nth-child(1), .tbl thead tr th:nth-child(2), .tbl thead tr th:nth-child(3){
    padding: 12px 16px;
}
.btnwrp{ text-align: right; margin-top: 10px;}
.txtrf17{
    text-align: right;
    font-size: 17px;
}
.cursor{ cursor: pointer;}
 .txtb{ color: blue;}
 .algr{text-align: right;}
.tbl thead tr th:nth-child(1), .tbl tbody tr th:nth-child(1){
    text-align: left;
    width: 30%;
}
.tbl thead tr th:nth-child(2), .tbl tbody tr td:nth-child(2){
    width: 40%;
    text-align: left;
    padding-left: 5px;
}
.tbl thead tr th:nth-child(3), .tbl tbody tr td:nth-child(3){
    text-align: left;
    width: 20%;
}
.tbl thead tr th{
    background-color: #788f9d4d;
}
.tbl tbody tr th{
    font-weight: normal;
}
.tbl tbody tr td:nth-child(4){
    cursor: pointer;
    color:blue;
}

.tbl tbody tr td:nth-child(4){
    font-size: 12px;
    text-align: left;
}
.tbl tbody tr th, .tbl tbody tr td{padding: 8px 16px;}
.tbl tbody tr:nth-child(odd) th, .tbl tbody tr:nth-child(odd) td{background: #f0f0f0;}
    
.navbar{
    flex-grow:1 ;
    position: relative;
    z-index: 100000;
    width: 102%;
    margin-left: -15px;    
}
#myForm{
    width: 100%;
    background: rgb(0 0 0 / 60%);
    padding: 20px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10000;
}

.wraperpopup{ 
    width: 25%;
    background: #fff;
    padding: 20px;
    margin: 7% auto;
    border-radius: 10px;
}

.w100{
    width: 100%;
}
.mb10{ margin-bottom: 20px;}
.mb10 div{
    width: 100%;
}
.inpw{
    width: 100%;
}
.ptb0 input{
    padding: 0px 10px;
    height: 40px;
}
.mtb10{
    margin: 10px 0px;
}
.fbold{font-weight: bold !important;}
.txt14{ font-size: 16px; margin-bottom: 10px; display: inline-block;}
.alertbox{width: 98%; margin-top: 10px; border: #bcd7bc 1px solid;}
.alertbox span{font-size: 18px; }

.chartwrap{
    background: #fff;
    border: #b8b5b5 1px solid;
    box-shadow: #ddd 0px 0px 9px 3px;
    margin-top: 20px;
    margin-left: 15px;
    position: relative;
}
.nodata{position: absolute; margin: auto; left: 0; top: 50px; bottom: 0; right: 50px; width: 150px; height: 95px;}

.spacer{
    border-top: #ddd 1px solid;
    margin-top: 25px;
    position: relative;
}
.textbng{
    font-family: 'Trade Gothic';
    position: absolute;
    font-size: 15px;
    background: #fff;
    padding: 2px 10px;
    margin-left: 43%;
    top: -10px;
}
.gbwrap{
    display: block;
    background: #4285f4;
    margin-top: 25px;
    border-radius: 2px;
    cursor: pointer;
    box-shadow: #d5d5d5 0px 0px 0px 4px;
    margin-bottom: 0;
    HEIGHT: 46PX;
    PADDING: 0PX;
}
.gbwrap:hover{
    background: #4285f4bd; 
    box-shadow: #d5d5d5c7 0px 0px 0px 4px;
}

.gimg{
    border-radius: 0px;
    width: 44px;
    MARGIN-TOP: 1PX;
    margin-left: 1px;
}
.gtext{
    font-size: 18px;
    color: #fff;
    position: relative;
    top: -15px;
    margin-left: 45px;
    font-family: 'Trade Gothic';
    letter-spacing: 1.1px;
    font-weight: bold;
}
.algr{
    display: inline;
}
.viewbtn, .pendingbtn{
    background: #ffffff;
    display: inline-block;
    border: #e1e1e1 2px solid;
    border-radius: 7px;
    height: 22px;
    margin-right: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}
.viewbtn:hover, .pendingbtn:hover{
    box-shadow: rgba(0, 0, 0, 0.6) 0px 2px 1px -1px, rgba(0, 0, 0, 0.644) 0px 1px 1px 0px, rgba(0, 0, 0, 0.20) 0px 1px 3px 0px;
}