/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.iconcss {
    background: #ddd;
    border-radius: 10px;
    top: 6px;
    position: relative;
    margin-right: 10px;
    height: 1.2em;
    width: 1.2em;
    padding: 6px 5px;
}

.fontsm {
    font-size: 10px;
}

.fieldset {
    width: 99%;
    border: #ddd 1px solid;
    border-radius: 6px;
    margin-left: 0;
    margin-top: 10px;
}

.legend {
    font-size: 13px;
    text-transform: initial;
    margin-left: 10px;
    color: #040404;
    font-weight: bold;
    background: #fff;
}

.upinp {
    width: 81%;
    height: 33px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin-top: 10px;
}

.uplbl {
    color: #084d7e;
    border: 1px solid currentColor;
    width: 100%;
    font-size: 1rem;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0.4rem 1.25rem;
    border-radius: 5px;
    margin: 10px 0px 0px;
}

.uplbl svg {
    margin-left: 30%;
}

.mtb10 {
    margin: 10px 0px 10px !important;
}

.choosefile {
    font-size: 14px;
    margin-left: 10px;
}

.wrp10 {
    margin: 0px 8px;
    width: 100%;
}
.dspflx{
    display: flex;
}

.accbg {
    background: #788f9d1c !important;
}

.accbg2 {
    background: #e5e5e5;
    padding: 0px 12px;
    border-radius: 10px;
    padding-top: 7px;
    font-size: 11px !important;
}

.btnupload {
    background: #788f9d !important;
    border: none;
    margin-left: 27% !important;
    padding: 10px 60px;
    border-radius: 4px;
    font-weight: bold !important;
    color: #fff;
    width: 50%;
}

.btnupload:hover {
    background: #557b92 !important;
}

.vh103 {
    height: 103vh;
}


.btnslctimg span:last-child {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    z-index: 0;
    overflow: hidden;
    position: absolute;
    border-radius: inherit;
    pointer-events: none;
}

.imgsecwrp {
    margin: auto;
    width: 95%;
}

.wrapsec2 {
    width: 95%;
    display: block;
    margin: auto;
}

.wrapsec12 {
    width: 99%;
    display: block;
    /* margin: auto; */
    margin-left: 10px;
}

.wrapsec2 h6 {
    padding-right: 10px;
    font-size: 16px;
    margin: 10px 0px;
}

.wrapsec2 h6:first-child {
    margin: 10px 0px 0px;
}

.selectbox {
    width: 100%;
    margin: 10px 10px 0px 0px !important;
}

.slidewrap {
    width: 95%;
    display: grid;
    margin: auto;
}

.p10 {
    padding: 10px;
}

.imgbox {
    width: 40%;
    min-height: 78px;
    padding: 10px;
    border-radius: 7px;
    margin-top: 15px;
}

.headcss {
    width: 52%;
    flex-shrink: 0;
}
.mt10{ margin-top: 10px;}
.headtxtcss{
    padding-right: 10px;
    font-size: 16px;
}

.ml10 {
    margin-left: 10px !important;
}

.mt10 {
    margin-top: 10px !important;
}

.pr10 {
    padding-right: 10px;
}

.w100 {
    width: 100%;
}

.pd10 span:first-child {
    padding: 10px;
}

.mlr {
    margin: 8px 0px !important;
}

.pd0 {
    padding: 0px !important;
}

.pdm {
    padding: 10px 10px 0px 10px;
}
.fntb14{
    font-weight: 600 !important;
    font-size: 13px !important;
}
.fntb141{
    font-weight: 600 !important;
    font-size: 13px !important;
    margin-left: 10px !important;
}
.btnprog {
    background: #788f9d !important;
    width: 50%;
    color: #fff !important;
    margin-left: 25% !important;
    font-weight: bold !important;
    margin: 15px 25% 10px !important;
    padding: 8px 16px 6px !important;
}
.wdg100{
    width: 100%;
    display: grid;
}
.wh100{
    width: 100%;
    height: 40px;
}

.btnprog:hover {
    background: #557b92 !important;
}
.btnprog:disabled,
.btnprog[disabled], .btnupload:disabled, .btnupload[disabled]{
    background: #ddd !important;
}

.algocont {
    margin-left: 10px;
    margin-top: 0px;
    width: 100%;
}

.icon {
    position: relative;
    top: 4px;
    right: 5px;
    background: #788f9d40;
    padding: 3px 2px;
    border-radius: 10px;
}

.wstain {
    padding-right: 10px;
    width: 132px;
    margin-top: 10px;
    margin-bottom: 15px;
}

.fieldset.slide div button {
    width: 100%;
    height: 40px !important;
}

.inputfile-box {
    position: relative;
}

.inputfile {
    display: none;
}

.container {
    display: inline-block;
    width: 100%;
}

.file-box {
    display: inline-block;
    width: 100%;
    border: 1px solid;
    padding: 5px 0px 5px 5px;
    box-sizing: border-box;
    height: calc(2rem - 2px);
}

.file-button {
    background: red;
    padding: 5px;
    position: absolute;
    border: 1px solid;
    top: 0px;
    right: 0px;
}

#file-upload-filename {
    font-weight: bold;
    text-align: center;
    padding: 5px 0px;
}

#file-filename {
    color: red;
    font-weight: bold;
    text-align: center;
    padding: 5px 0px;

  }
  #file-filename-addTenant{
    color: red;
    text-align: left;
    margin-left: 3px;
    margin-top: 8px;
  }
  .dot {
    height: 9px;
    width: 9px;
    background-color: rgb(226, 26, 26);
    border-radius: 50%;
    display: inline-block;
  }



.dspgrid {
    display: grid;
    width: 100%;
    margin-bottom: 10px;
}
.wh200{
    margin-top: 10px;
    width: 200px;
    height: 200px;
    border: #8d8d8d70 1px solid;
    border-radius: 7px;
}
.w95{
    width: 94%;
    margin-left: 1%;
}
.imgview{
    margin-top: 0;
    position: relative;
    margin-bottom: 0px;
}
.imgview span{
    display: block;
    background: -webkit-linear-gradient(top, #cccccc 0%,#eeeeee 100%);
    font-size: 16px;
    padding: 10px;
    border-bottom: #ccc 1px solid;
    color: #112954;
    text-align: center;
}
.drp{margin-top: 10px;}
.drp>div>span, .drp>div>div>div>svg{
    display: none;
}
.drp::after{
    content: '';
    position: absolute;
    top: 16px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #0000008c;
    clear: both;
    right: 3.3%;
}
.css-1jqq78o-placeholder{color: #000 !important;}


.css-13cymwt-control{border-color: hsl(0deg 0.66% 75.43%) !important;}

.css-1u9des2-indicatorSeparator{
    display: none;
}
.imgview img{
    position: absolute;
    margin: 25% 43%;
    width: 17%;
}
.imgviewwrap{
    width: 100%;
    border: 2px solid #ccc;
}
.drp>*{
    font-size: 15px;
    color: #2c2b2b !important; 
}
.drp div[class*="placeholder"], .drp div[class*="menu"]{
    font-size: 1.1em;
}
.rdo_btn .jss11 {
    padding: 0px 10px !important;
}
.rdo_btn .jss25{
    margin-left: 0px;
}
.rdo_btn .jss26, .rdo_btn .jss24 {
    margin: 0px !important;
    padding: 0px;
}
.rdo_btn.jss12{width:auto !important}
.rdo_btn .jss24.jss26 .jss25 {
    color: red;
}

@media (min-width: 900px) {
    .css-1v2c24a-MuiGrid-root,
    .css-cpk47q-MuiGrid-root,
    .css-1v2c24a-MuiGrid-root {
        width: calc(100% - 24px) !important;
    }
}